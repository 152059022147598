/* Header.css */
.header {
  background-color: #1f1f1f;
  color: #ffffff;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 2px -2px #000;
}

.brand {
  display: flex;
  align-items: center;
}

.logo {
  height: 50px;
  margin-right: 15px;
}

.titleName {
  font-size: 26px;
  font-weight: bold;
  color: #bb86fc;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

nav ul li {
  margin: 0 15px;
}

nav ul li a {
  color: #e0e0e0;
  text-decoration: none;
  padding: 5px 10px;
}

nav ul li a:hover {
  background-color: #3700b3;
  color: #ffffff;
  border-radius: 4px;
}
