/* StateSearch.css */

/* Apply a dynamic background gradient animation to the entire page */
body {
  background: linear-gradient(120deg, #1c1c1c, #2e2e2e, #1c1c1c);
  background-size: 400% 400%;
  animation: gradientBackground 15s ease infinite;
  margin: 0;
  padding: 0;
  color: #ffffff;
  font-family: 'Arial', sans-serif;
  overflow: hidden;
}

/* Container for the state search content */
.search-container {
  text-align: center;
  padding: 50px 0;
  color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  animation: fadeIn 1s ease-in-out;
}

.search-container.moved-up {
  justify-content: flex-start;
  padding-top: 20px;
}

.search-container h2 {
  color: #ffffff;
  margin-bottom: 20px;
  font-size: 28px;
  animation: slideIn 1.5s ease-out;
}

.search-container .searchBar {
  margin-bottom: 20px;
}

.search-container .dataTable {
  margin-top: 20px;
  animation: zoomIn 1.5s ease;
}

/* Button hover effect */
.btn {
  background-color: #6200ee;
  color: #ffffff;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: transform 0.3s, background-color 0.3s;
}

.btn:hover {
  background-color: #3700b3;
  transform: scale(1.05);
}

/* Animations */
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes slideIn {
  0% { transform: translateY(20px); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
}

@keyframes zoomIn {
  0% { transform: scale(0.9); opacity: 0; }
  100% { transform: scale(1); opacity: 1; }
}

/* Dynamic background animation */
@keyframes gradientBackground {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}
