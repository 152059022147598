/* SearchBar.css */

/* Wrapper for the search bar */
.wrap {
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

/* Search bar container */
.search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
}

/* Input field for the search bar */
.searchTerm {
  width: calc(100% - 80px);
  border: 2px solid #6200ee;
  border-right: none;
  padding: 0 10px; /* Adjusted padding to remove extra height */
  height: 40px;
  line-height: 40px; /* Ensures text is vertically centered */
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #e0e0e0;
  background-color: #1e1e1e;
  font-size: 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center; /* Vertically centers the text inside the input */
}

.searchTerm:focus {
  color: #bb86fc;
  border-color: #bb86fc;
}

/* Search button next to the input field */
.searchButton {
  margin-top: 5px;
  width: 80px;
  height: 40px;
  border: none;
  background: #6200ee;
  color: #ffffff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  box-sizing: border-box;
  padding: 0; /* Remove any padding to avoid height differences */
}

.searchButton:hover {
  background: #3700b3;
}

/* Icon inside the search button */
.searchButton i {
  font-size: 18px;
  color: #ffffff;
}
