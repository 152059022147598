/* Home.css */

/* Container for the home content */
.home-container {
  text-align: center;
  padding: 50px 0;
  color: #ffffff; /* Light text color for contrast */
  min-height: 100vh; /* Ensure full height for better aesthetics */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* To hide any overflow during animations */
  animation: fadeIn 1s ease-in-out;
}

/* Heading styles */
.home-container h1 {
  font-size: 36px;
  color: #ffffff;
  margin-bottom: 20px;
  animation: fadeIn 1s ease-in-out;
}

/* Paragraph styles */
.home-container p {
  font-size: 18px;
  color: #bbbbbb;
  margin-bottom: 40px;
  animation: slideIn 1.5s ease-out;
}

/* Action buttons container */
.home-actions {
  margin-top: 30px;
  display: flex;
  gap: 20px;
  animation: zoomIn 2s ease;
}

/* Button styles */
.home-actions .btn {
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 5px;
  transition: transform 0.3s, background-color 0.3s;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* Primary button styles */
.home-actions .btn-primary {
  background-color: #6200ee;
  color: #ffffff;
}

/* Secondary button styles */
.home-actions .btn-secondary {
  background-color: #03dac6;
  color: #1c1c1c;
}

/* Button hover effects */
.home-actions .btn:hover {
  transform: scale(1.1);
  background-color: #3700b3;
}

/* Dynamic background animation */
@keyframes gradientBackground {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Animations for elements */
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes slideIn {
  0% { transform: translateY(20px); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
}

@keyframes zoomIn {
  0% { transform: scale(0.9); opacity: 0; }
  100% { transform: scale(1); opacity: 1; }
}
