/* DataTable.css */

.table-container {
  width: 100%;
  max-height: 100vh; /* Adjust the maximum height as needed */
  overflow-x: auto; /* Horizontal scrolling */
  overflow-y: auto; /* Vertical scrolling */
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  min-width: 600px; /* Minimum width to ensure columns don't squish on small screens */
}

.responsive-table th,
.responsive-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  color: #ffffff;
}

.responsive-table th {
  background-color: #333;
  font-weight: bold;
}

.responsive-table tr:nth-child(even) {
  background-color: #2c2c2c;
}

.responsive-table tr:hover {
  background-color: #444;
}

/* Make the table responsive for small devices */
@media screen and (max-width: 768px) {
  .responsive-table {
    font-size: 12px; /* Adjust font size for readability */
  }
}

@media screen and (max-width: 480px) {
  .responsive-table {
    min-width: unset; /* Remove minimum width on very small screens */
    width: 100%;
  }

  .responsive-table th, .responsive-table td {
    padding: 8px; /* Adjust padding for smaller screens */
  }
}


/* Styles for the react-select component */

/* Control: The main select box */
.select__control {
  background-color: #ffffff;
  border-color: #444;
  color: #000000;
}

/* Menu: The dropdown menu */
.select__menu {
  background-color: #ffffff;
  color: #2e2e2e;
}

/* MultiValue: The selected items (chips) in the select box */
.select__multi-value {
  background-color: #6200ee;
  color: #2e2e2e;
}

/* MultiValueLabel: The label within the selected item (chip) */
.select__multi-value__label {
  background-color: #ffffff;
  color: #565656;
}

/* MultiValueRemove: The remove button within the selected item (chip) */
.select__multi-value__remove {
  background-color: #ffffff;
  color: #000000;
}

.select__multi-value__remove:hover {
  background-color: #3700b3;
  color: #000000;
}

/* Option: Each option in the dropdown */
.select__option {
  background-color: #ffffff;
  color: #000000;
}

.select__option--is-focused {
  background-color: #6200ee;
}

.select__option--is-selected {
  background-color: #3700b3;
  color: #858585;
}

/* Placeholder: The placeholder text in the select box */
.select__placeholder {
  color: #ffffff;
}

/* SingleValue: The selected single value in the select box */
.multi-select__single-value {
  color: #2e2e2e;
}

/* Input: The text input inside the select box */
.multi-select__input {
  color: #ffffff;
}

/* Multi-select container */
.multi-select {
  margin-top: 5px;
}
