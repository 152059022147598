/* App.css */

/* Global Styles */
body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #121212;
  color: #e0e0e0;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h1, h2, h3 {
  color: #ffffff;
}

a {
  color: #bb86fc;
  text-decoration: none;
}

a:hover {
  color: #3700b3;
  text-decoration: underline;
}

button {
  background-color: #03dac6;
  color: #000;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #018786;
}

input, select {
  background-color: #1e1e1e;
  color: #ffffff;
  border: 1px solid #333;
  padding: 10px;
  border-radius: 4px;
  margin-top: 5px;
}

input::placeholder {
  color: #b0b0b0;
}
